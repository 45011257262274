import React, { useState } from 'react'

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"

import arrow    from "../../images/upcc/arrow.svg"
import Controls from "./controls"


export default () => {

  let { allContentfulArteYCultura: { nodes: data }, allContentful2023Noticia: { nodes: data2 } } = useStaticQuery(
    graphql`
      query {
        allContentfulArteYCultura(filter: {node_locale: {eq: "en-US"}, categorylink: {in: "5"}, activarPublicacion: {eq: true}}, sort: {fields: fechaDeOrden, order: DESC}) {
          nodes {
            titulo
            imagenDeLaEntrada { file { url } }
            tituloDeRespaldo
            descripcion
            descripcionEnElBlog
            fecha
            fechaDeOrden
          }
        },
        allContentful2023Noticia(filter: {node_locale: {eq: "en-US"}}) {
          nodes {
            title
            subtitle
            image { file { url } }
            description
            date
          }
        }
      }
    `)

  data.forEach(e => {
    e.titleURL    = e.titulo
    e.title       = e.tituloDeRespaldo
    e.subtitle    = e.descripcion
    e.image       = e.imagenDeLaEntrada
    e.description = e.descripcionEnElBlog
    e.date        = e.fecha
    e.path        = 'arteycultura'
  })

  data2.forEach(e => {
    e.titleURL    = e.title
    e.path        = 'noticias'
  })

  // data = data2.concat(data)


  const N = data.length
  const n = 12

  for(let i = 0; i < n; i++) if(data[i]) data[i].visible = true

  let _visible = N - n ? true : false

  let [posts, setPosts]     = useState(data)
  let [visible, setVisible] = useState(_visible)

  function more() {
    let _posts = [...posts]

    const i = _posts.findIndex(e => !e.visible)

    if(i != -1) {
      for(let j = i; j < i + n; j++) {
        if(_posts[j])
          _posts[j].visible = true
      }
    }

    setPosts(_posts)

    if(_posts.findIndex(e => !e.visible) == -1) setVisible(false)
  }


  return (
    <>
      <section class="upcc-menu">
        <nav>
          <Link to={`/`}>Inicio</Link>&nbsp;/&nbsp;
          <Link to={`/noticias#more`}>Noticias</Link>&nbsp;/&nbsp;
          <Link to={`/noticias/artes-visuales#more`}>Artes Visuales</Link>
        </nav>
      </section>

      <Controls r={5}/>

      <section class="upcc-cards two">
        {
          posts.map(( post ) => {

            if(post.visible) return (
              <article class="upcc-card">
                <Link to={`/${post.path}/${normalize(post.titleURL)}`}>
                  <img class="upcc-card-img" src={ post.image.file.url } />
                </Link>
                <span class="upcc-tag upcc-card-tag">NOTICIAS</span>
                <Link to={`/${post.path}/${normalize(post.titleURL)}`}>
                  <h5 class="upcc-h5 upcc-card-title">{ post.title } { post.subtitle }</h5>
                </Link>
                <p class="upcc-small upcc-card-desc">{ post.description }</p>
                <footer>
                  <span class="upcc-small upcc-card-date">{ date(post.date).str }</span>
                  <Link class="upcc-card-more" to={`/${post.path}/${normalize(post.titleURL)}`}>
                    <p class="upcc-small underline">Leer más</p>
                    <img src={arrow} />
                  </Link>
                </footer>
              </article>
            )
          })
        }
      </section>

      <section class="upcc-more">
        {visible
          ? <button class="upcc-button" onClick={more}>Ver más</button>
          : <i/>
        }
      </section>
    </>
  )
}